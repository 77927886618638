var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"left":"","bottom":"","min-width":"320px","max-width":"400px","max-height":"70%","offset-y":"","offset-x":"","transition":"slide-y-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":"","depressed":""},on:{"click":function($event){$event.stopPropagation();}}},Object.assign({}, onMenu, onTooltip)),[_c('img',{attrs:{"height":"18","width":"18","src":require("../../../assets/icons/user.svg"),"alt":"user"}})])]}}],null,true)},[_c('span',[_vm._v("Perfil de usuario")])])]}}])},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$store.state.user.name)+" "+_vm._s(_vm.$store.state.user.apellidos)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$store.state.user.email)+" ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","to":{
              name: 'usersEdit',
              params: { id: this.$store.state.user.id },
            },"target":"_blank"}},[_c('v-icon',[_vm._v("open_in_new")])],1)],1)],1)],1),_c('v-divider'),_c('v-subheader',[_vm._v("PREFERENCIAS DE INTERFICIE")]),_c('v-list',[_c('v-list-item',{staticClass:"d-flex justify-center"},[_c('div',[_vm._v("Clara")]),_c('v-switch',{staticClass:"ml-8 mr-6",model:{value:(_vm.darkMode),callback:function ($$v) {_vm.darkMode=$$v},expression:"darkMode"}}),_c('div',[_vm._v("Oscura")])],1),_c('v-list-item',{staticStyle:{"min-height":"unset"}},[_c('span',{staticClass:"caption"},[_vm._v("A")]),_c('v-spacer'),_c('span',{staticClass:"title"},[_vm._v("A")])],1),_c('v-list-item',[_c('v-slider',{staticClass:"mb-4",attrs:{"tick-labels":_vm.fontSlider.options,"max":_vm.fontSlider.max,"min":_vm.fontSlider.min,"step":_vm.fontSlider.step,"ticks":"always","tick-size":"5"},model:{value:(_vm.fontSize),callback:function ($$v) {_vm.fontSize=$$v},expression:"fontSize"}})],1),_vm._v(" "+_vm._s(_vm.fontSlider.fontSize)+" ")],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","small":"","text":"","tile":"","dark":""},on:{"click":_vm.logout}},[_vm._v(" Desconectar "),_c('v-icon',{staticClass:"ml-2",attrs:{"size":"18"}},[_vm._v("power_settings_new")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }