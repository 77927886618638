<template>
  <v-menu
    left
    bottom
    min-width="320px"
    max-width="400px"
    max-height="70%"
    offset-y
    offset-x
    transition="slide-y-transition"
    :close-on-content-click="false"
  >
    <template #activator="{ on: onMenu }">
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip }">
          <v-btn v-on="{ ...onMenu, ...onTooltip }" @click.stop="" fab x-small depressed>
            <img height="18" width="18" src="../../../assets/icons/user.svg" alt="user" />
          </v-btn>
        </template>
        <span>Perfil de usuario</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ $store.state.user.name }}
              {{ $store.state.user.apellidos }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ $store.state.user.email }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              icon
              :to="{
                name: 'usersEdit',
                params: { id: this.$store.state.user.id },
              }"
              target="_blank"
            >
              <v-icon>open_in_new</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-subheader>PREFERENCIAS DE INTERFICIE</v-subheader>
      <v-list>
        <v-list-item class="d-flex justify-center">
          <div>Clara</div>
          <v-switch class="ml-8 mr-6" v-model="darkMode"></v-switch>
          <div>Oscura</div>
        </v-list-item>
        <v-list-item style="min-height: unset">
          <span class="caption">A</span>
          <v-spacer></v-spacer>
          <span class="title">A</span>
        </v-list-item>
        <v-list-item>
          <v-slider
            v-model="fontSize"
            class="mb-4"
            :tick-labels="fontSlider.options"
            :max="fontSlider.max"
            :min="fontSlider.min"
            :step="fontSlider.step"
            ticks="always"
            tick-size="5"
          />
        </v-list-item>
        {{ fontSlider.fontSize }}
      </v-list>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" small text tile dark @click="logout">
          Desconectar
          <v-icon class="ml-2" size="18">power_settings_new</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    fontSlider: {
      min: 0.8,
      max: 1.2,
      step: 0.1,
      options: ["xs", "s", "m", "l", "xl"],
    },
  }),
  methods: {
    updateFontSize(size) {
      const [html] = document.getElementsByTagName("html");
      html.style.fontSize = size + "em";
    },
    switchDarKMode(isDark) {
      this.$vuetify.theme.dark = isDark;
    },
    logout() {
      this.$store.dispatch("logout");
    },
  },
  computed: {
    darkMode: {
      get() {
        return this.$store.state.userSettings.darkMode;
      },
      set(value) {
        this.switchDarKMode(value);
        this.$store.dispatch("toggleDarkMode", value);
      },
    },
    fontSize: {
      get() {
        return this.$store.state.userSettings.fontSize;
      },
      set(value) {
        this.updateFontSize(value);
        this.$store.dispatch("updateFontSize", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped />
